import React from "react";
import 'react-tooltip/dist/react-tooltip.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'animate.css/animate.css';
import './src/scss/global.scss';
import { SpeedInsights } from "@vercel/speed-insights/react";

export const onRouteUpdate = ({ location }) => {
    window.scrollTo(0, 0);
};

export const wrapRootElement = ({ element }) => (
    <>
      {element}
      <SpeedInsights />
    </>
);
